export const LogEvent = [
  'addToTriage',
  'alreadyCanceled',
  'canceledBySnap',
  'change',
  'claim reversal',
  'claim',
  'claimJumperError',
  'create',
  'dur',
  'eligibility check',
  'fill reversal',
  'inventory',
  'lot accuracy',
  'ndc manual entry',
  'note',
  'order return',
  'parataCancel',
  'parataComplete',
  'parataFill',
  'prepareToFulfillment',
  'prescriberPracticeChange',
  'print',
  'priorAuth',
  'rejectEscript',
  'remove',
  'removeFromTriage',
  'replacement',
  'scannedProductInfo',
  'sendToFulfillmentDelay',
  'sentToFulfillment',
  'shippingLabelRequest',
  'surescriptsCancelRx',
  'surescriptsChangeRequest',
  'surescriptsChangeResponse',
  'surescriptsRenewalRequest',
  'surescriptsRxTransfer',
  'transfer',
  'updatedOrder',
  'updateOrderBySnapchat',
  'updateOTCProduct',
  'updateOTCProductsBySnapchat',
  'updatePackingMaterial',
  'verification',
  'view',
] as const

export type LogEvent = (typeof LogEvent)[number]
