import { LaunchDarkly } from '@truepill/tpos-types'
import { useFlag } from 'providers/LaunchDarklyProvider'
import NotesListAlt from './NotesListAlt'
import NotesListOriginal from './NotesListOriginal'
import NotesListSkeleton from './NotesListSkeleton'
import type { NoteListProps } from './types'

const NotesList = (props: NoteListProps): JSX.Element => {
  const supportCSANotes = useFlag(LaunchDarkly.FeatureFlags.TEMP_SUPPORT_CSA_NOTES)

  if (props.isLoadingLogs) {
    return <NotesListSkeleton />
  }

  if (supportCSANotes) {
    return <NotesListAlt {...props} />
  }
  return <NotesListOriginal {...props} />
}

export default NotesList
export { NoteEntry } from './NotesListOriginal'
