import { useEffect } from 'react'
import { CopayStatus } from '@truepill/tpos-types'
import BreadCrumb from 'components/BreadCrumb'
import CopayRequestEntry from 'components/CopayRequest'
import CopayRequestHeading from 'components/CopayRequestHeading'
import CopayTopBanner from 'components/CopayRequestTopBanner'
import LoadingSpinner from 'components/Loading'
import MessagesPane from 'components/MessagesPane'
import NotesPane from 'components/NotesPane'
import {
  FilledHeader,
  PageContainer,
  PageContent,
  PageHeading,
  StickyPageHeadingContainer,
  UnPaddedPageContainer,
} from 'components/PageStructure'
import ToolBar, { LogButton, MedicalHistoryButton, NotesButton } from 'components/ToolBar'
import TopBanner from 'components/TopBanner'
import { ProblemList } from 'components/TopBanner/OrderTriageTopBanner'
import TriageIssues from 'components/TriageIssues'
import { Box, Grid } from 'grommet'
import { useStateWithDelay, useUnlockCopay, useCopayLock } from 'hooks'
import useCopayRequest from 'hooks/navigation/useCopayRequest'
import useLogs from 'hooks/navigation/useLogs'
import useErrorToast from 'hooks/toast/useErrorToast'
import useLogPageView from 'hooks/useLogPageView'
import useShouldIncludePatientRelatedLogs from 'hooks/useShouldIncludePatientRelatedLogs'
import styled from 'styled-components'
import { contrastBackgroundColor } from 'styles/styleVariables'
import type { Patient } from 'types'
import ApiRequestDetails from '../OrderView/ApiRequestDetails'
import PatientDetails from '../OrderView/PatientDetails'
import ActionButtons from './ActionButtons'

const PrescriptionsList = styled.ul`
  border: 0.25rem solid ${contrastBackgroundColor};
  border-radius: 0.25rem;
  background-color: ${contrastBackgroundColor};
  > li {
    background-color: transparent;
    border-color: transparent;
    margin-top: 0;
    margin-bottom: 0.25rem;
    border: none;
  }
`

const SubTitle = styled.h4`
  font-weight: 500;
  font-size: 1.1em;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
`
type CopayRequestViewProps = {
  copayRequestId: string
  onClickCopayRequestEntry?: () => void
}

const CopayRequestView = ({ copayRequestId, onClickCopayRequestEntry }: CopayRequestViewProps): JSX.Element => {
  const { copayRequest, apiRequest, loading, error } = useCopayRequest(copayRequestId)

  useLogPageView(
    {
      page: `copayRequest/${copayRequest?.status}`,
      copayRequestId: copayRequestId,
    },
    Boolean(!loading && copayRequest),
  )

  const shouldIncludePatientRelatedLogs = useShouldIncludePatientRelatedLogs()
  const { setCopayLock, copayLockedBy, isCopayLockedByMe } = useCopayLock(copayRequestId)
  const { logs, loading: isLoadingLogs } = useLogs(
    { copayRequestId, patientId: copayRequest?.patientId },
    { shouldIncludePatientRelatedLogs },
  )
  const showErrorToast = useErrorToast()

  useUnlockCopay(copayRequestId)

  useEffect(() => {
    const tryLock = async () => {
      try {
        await setCopayLock()
      } catch (e) {
        showErrorToast(`Failed to lock copay ${(e as Record<string, string>).message}`)
      }
    }

    if (!copayLockedBy) {
      tryLock()
    }
  }, [copayRequestId, copayLockedBy, setCopayLock])

  const [patientValues, setPatientValues] = useStateWithDelay<Patient>(copayRequest?.patient, loading)
  const disableCopay: boolean | undefined = !isCopayLockedByMe && !!copayLockedBy

  if (error) {
    return (
      <UnPaddedPageContainer>
        <p>Error loading Copay Request {JSON.stringify(error)}</p>
      </UnPaddedPageContainer>
    )
  }

  if (loading || !copayRequest) {
    return <LoadingCopayRequest />
  }

  // const setEditOptions = async (value: string) => {
  //   setEditValue(value)
  // }

  // const editValueButton = (editValue: string): JSX.Element => {
  //   if (disableCopay) return <></>
  //   return (
  //     <FloatingEditSpan onClick={() => setEditOptions(editValue)}>
  //       <SmallIconWrapper>
  //         <EditIcon fill={contrastColor} />
  //       </SmallIconWrapper>
  //     </FloatingEditSpan>
  //   )
  // }

  const inTriage = copayRequest.status === CopayStatus.Triage
  let innerContents = <></>

  if (!loading) {
    innerContents = (
      <>
        <Grid data-testid="order" columns={['flex', 'flex', 'flex']} gap="0.625rem">
          <Box data-test-column="patient">
            <FilledHeader>Patient</FilledHeader>
            {!!copayRequest?.patient && !!patientValues && (
              <PatientDetails
                patient={copayRequest.patient}
                patientValues={patientValues}
                setPatientValues={setPatientValues}
              />
            )}
          </Box>
        </Grid>
        {copayRequest.copayRequestFills.length > 0 && (
          <Box data-testid="prescriptions">
            <SubTitle>Prescriptions</SubTitle>
            <PrescriptionsList>
              <CopayRequestEntry
                copayRequest={copayRequest}
                onClick={onClickCopayRequestEntry}
                inline
                hideCopayRequestDetails
              />
            </PrescriptionsList>
          </Box>
        )}
        <ApiRequestDetails apiRequest={apiRequest} />
      </>
    )
  }

  const copayLevelNotes =
    logs?.filter(note => !!note.copayRequestId && !note.fillId && note.event === 'note' && !note.isArchived) || []

  return (
    <>
      {inTriage && (
        <TopBanner message={'Triage'}>
          {' '}
          <ProblemList>
            {copayRequest.triages.map((triage, i) => (
              <li key={i}>
                {triage.reason}: {triage.message}
              </li>
            ))}
          </ProblemList>
        </TopBanner>
      )}
      <PageContainer>
        <StickyPageHeadingContainer>
          <CopayTopBanner copayRequest={copayRequest} disableCopay={disableCopay} copayLockedBy={copayLockedBy} />
          <BreadCrumb />
          <PageHeading>
            <CopayRequestHeading copayRequest={copayRequest} />
            {copayRequest && (
              <ActionButtons copayRequest={copayRequest} onCancel={() => undefined} disabled={disableCopay} />
            )}
          </PageHeading>
        </StickyPageHeadingContainer>
        <PageContent>
          <Box direction="column" margin={{ left: '1.875rem' }} width="100rem">
            {inTriage && <TriageIssues item={copayRequest} />}
            {innerContents}
          </Box>
          <ToolBar>
            {/* {!editValue && <EditButton copayRequestId={copayRequestId} />} */}
            <LogButton logs={logs} copayRequestId={copayRequestId} isCopay={true} />
            <NotesButton notesCount={copayLevelNotes.length} showBadge={copayLevelNotes.length > 0} />
            {copayRequest?.patient && <MedicalHistoryButton patientId={copayRequest.patient._id} />}
          </ToolBar>
        </PageContent>
      </PageContainer>
      {copayRequest && <MessagesPane logs={logs} />}
      {copayRequest && (
        <NotesPane
          isLoadingLogs={isLoadingLogs}
          filterOutEncounterRecordsFromOtherThanAllTabs
          logs={logs}
          item={copayRequest}
          patient={copayRequest?.patient}
          tabs={['Copay', 'Patient']}
          defaultTab="Copay"
          tabsIncludedInAllTab={shouldIncludePatientRelatedLogs ? ['Patient', 'Fill', 'Copay', 'Order', 'Rx'] : []}
        />
      )}
      {/* TODO - get attachments working with copays
      {copayRequest && <AttachmentsPane orderId={order._id} patientId={patientValues?._id} />} */}
    </>
  )
}

const LoadingCopayRequest = () => (
  <UnPaddedPageContainer>
    <LoadingSpinnerContainer>
      <LoadingSpinner />
    </LoadingSpinnerContainer>
  </UnPaddedPageContainer>
)

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 15rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

// const FloatingEditSpan = styled.span`
//   cursor: pointer;
//   float: right;
// `

export default CopayRequestView
