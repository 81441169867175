import { useCallback, useEffect, useState } from 'react'
import { OrderStatus, OtcProductStatus } from '@truepill/tpos-types'
import AttachmentsPane from 'components/AttachmentsPane'
import BreadCrumb from 'components/BreadCrumb'
import DataTable from 'components/DataTable'
import LoadingSpinner from 'components/Loading'
import MessagesPane from 'components/MessagesPane'
import NotesPane from 'components/NotesPane'
import { OrderEntry } from 'components/Order'
import OrderHeading from 'components/OrderHeading'
import OrderTopBanner from 'components/OrderTopBanner'
import OTCPackingItemList from 'components/OTCPackingItemList'
import {
  FilledHeader,
  PageContainer,
  PageContent,
  PageHeading,
  StickyPageHeadingContainer,
  UnPaddedPageContainer,
} from 'components/PageStructure'
import { EditMode } from 'components/RXPageStructure'
import { CompoundTextInput, ListRowLabel, ListRowValue, RXCell } from 'components/RXTable'
import Lozenge from 'components/Tiles/Lozenge'
import ToolBar, {
  EditButton,
  LogButton,
  MedicalHistoryButton,
  NotesButton,
  ReturnItemListButton,
} from 'components/ToolBar'
import TopBanner from 'components/TopBanner'
import TriageIssues from 'components/TriageIssues'
import UserEventTimer from 'components/UserEventTimer'
import { Box, CheckBox, Grid } from 'grommet'
import { useEditMode, useEditValue, useOrderLock, useStateWithDelay, useUnlockOrder } from 'hooks'
import useLogs from 'hooks/navigation/useLogs'
import useOrder from 'hooks/navigation/useOrder'
import useErrorToast from 'hooks/toast/useErrorToast'
import useLogPageView from 'hooks/useLogPageView'
import useShouldIncludePatientRelatedLogs from 'hooks/useShouldIncludePatientRelatedLogs'
import useCriticalNotesModal from 'modals/useCriticalNotesModal'
import useExcessiveCsRejectionsModal from 'modals/useExcessiveCsRejectionsModal'
import styled from 'styled-components'
import { contrastBackgroundColor, primaryColor, primaryColorDark } from 'styles/styleVariables'
import type { Address, GuestPatient, Order, Patient, ShippingMethod } from 'types'
import { PaymentType } from 'types'
import { usdFormatter } from 'utils'
import ActionButtons from './ActionButtons'
import ApiRequestDetails from './ApiRequestDetails'
import PackingMaterialsList from './components/PackingMaterialsList'
import { PaymentAndShipping } from './components/PaymentAndShipping'
import { EventLog } from './EventLog'
import GuestPatientDetails from './GuestPatientDetails'
import OrderViewMode from './OrderViewMode'
import PackingTasks from './PackingTasks'
import PatientDetails from './PatientDetails'
import References from './References'

export { default as OrderViewMode } from './OrderViewMode'

const PrescriptionsList = styled.ul`
  border: 0.25rem solid ${contrastBackgroundColor};
  border-radius: 0.25rem;
  background-color: ${contrastBackgroundColor};
  > li {
    background-color: transparent;
    border-color: transparent;
    margin-top: 0;
    margin-bottom: 0.25rem;
    border: none;
  }
`

const SubTitle = styled.h4`
  font-weight: 500;
  font-size: 1.1em;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
`

const FillList = (props: { order: Order; onClickOrderEntry?: () => void; inModal?: boolean }) => {
  const { order, onClickOrderEntry, inModal } = props

  return (
    <Box data-testid="prescriptions">
      <SubTitle>Prescriptions</SubTitle>
      <PrescriptionsList>
        <OrderEntry
          showFillsOnly={true}
          order={order}
          onClick={onClickOrderEntry}
          inline
          hideOrderDetails
          editableEntries={!inModal}
          isRxComingFromOrderPage={true}
        />
      </PrescriptionsList>
    </Box>
  )
}

/**
 * This component intentionally doesn't use the FormDataProvider setup because
 * it's neatly self-contained and so doing everything here sidesteps issues with
 * nested FormDataProviders. This pattern with `useStateWithDelay` can be used
 * to do similar cleanup elsewhere for any editing self-contained under a single
 * component (including the action buttons).
 */

type OrderViewProps = {
  inModal?: boolean
  orderId: string
  mode: OrderViewMode
  onClickOrderEntry?: () => void
}

const OrderView = ({ inModal = false, orderId, mode, onClickOrderEntry }: OrderViewProps): JSX.Element => {
  const { order, apiRequest, loading, error } = useOrder(orderId)

  useLogPageView(
    {
      page: `order/${order?.status}${order?.inTriage ? '/Triage' : ''}`,
      orderId: orderId,
    },
    Boolean(!loading && order),
  )

  const shouldIncludePatientRelatedLogs = useShouldIncludePatientRelatedLogs()
  const { setOrderLock, orderLockedBy, isOrderLockedByMe } = useOrderLock(orderId)
  const showErrorToast = useErrorToast()
  const { logs, loading: isLoadingLogs } = useLogs(
    { orderId, patientId: order?.patient?._id },
    { shouldIncludePatientRelatedLogs },
  )
  const [editValue] = useEditValue()
  const [editMode] = useEditMode()
  const [patientValues, setPatientValues] = useStateWithDelay<Patient>(order?.patient, loading)
  const [guestPatientValues, setGuestPatientValues] = useStateWithDelay<GuestPatient>(order?.guestPatient, loading)
  const [shippingAddressValues, setShippingAddressValues] = useStateWithDelay<Address>(order?.shippingAddress, loading)
  const [shippingMethod, setShippingMethod] = useStateWithDelay<ShippingMethod>(order?.shippingMethod, loading)
  const [paymentType, setPaymentType] = useStateWithDelay<PaymentType>(order?.paymentType || PaymentType.cash, loading)
  const [isReplacement, setIsReplacement] = useStateWithDelay<boolean>(order?.isReplacement, loading)
  const [isReservedForInvestigation, setIsReservedForInvestigation] = useStateWithDelay<boolean>(
    order?.isReservedForInvestigation,
    loading,
  )
  const [trackingNumber, setTrackingNumber] = useState<string>('')
  const [otcSkuValues, setOtcSkuValues] = useStateWithDelay<{ [id: string]: string | null }>(
    Object.fromEntries(order?.otcProducts?.map(({ _id, sku }) => [_id, sku]) ?? []),
    loading,
  )
  const disableOrder: boolean | undefined = !isOrderLockedByMe && !!orderLockedBy

  useCriticalNotesModal({ order, orderOnlyNotes: true })
  useExcessiveCsRejectionsModal({ order, logs })
  useUnlockOrder(orderId)

  useEffect(() => {
    if (!orderLockedBy) {
      setOrderLock().catch(e => showErrorToast(`Failed to lock order ${(e as Record<string, string>).message}`))
    }
  }, [orderId, orderLockedBy, setOrderLock, showErrorToast])

  const onCancel = useCallback(() => {
    !!order?.guestPatient && setGuestPatientValues(order.guestPatient)
    !!order?.otcProducts &&
      setOtcSkuValues(Object.fromEntries(order?.otcProducts?.map(({ _id, sku }) => [_id, sku]) ?? []))
    !!order?.patient && setPatientValues(order.patient)
    !!order?.shippingAddress && setShippingAddressValues(order.shippingAddress)
    !!order?.shippingMethod && setShippingMethod(order.shippingMethod)
    !!order?.paymentType && setPaymentType(order.paymentType || PaymentType.cash)
    typeof order?.isReplacement !== 'undefined' && setIsReplacement(order.isReplacement)
    typeof order?.isReservedForInvestigation !== 'undefined' &&
      setIsReservedForInvestigation(order.isReservedForInvestigation)
  }, [
    order?.guestPatient,
    order?.otcProducts,
    order?.patient,
    order?.shippingAddress,
    order?.shippingMethod,
    order?.isReplacement,
    order?.paymentType,
    order?.isReservedForInvestigation,
    setPatientValues,
    setGuestPatientValues,
    setOtcSkuValues,
    setShippingAddressValues,
    setShippingMethod,
    setPaymentType,
    setIsReplacement,
    setIsReservedForInvestigation,
  ])

  useEffect(() => {
    const shipments = order?.shipments ?? []
    if (shipments.length) setTrackingNumber(shipments[shipments.length - 1].trackingNumber)
  }, [order?.shipments])

  useEffect(() => {
    if (order?.paymentType) setPaymentType(order?.paymentType)
  }, [order?.paymentType, setPaymentType])

  let innerContents = <></>
  const orderIsInPackingStatus = order?.status === OrderStatus.Packing

  if (error) {
    return (
      <UnPaddedPageContainer>
        <p>Error loading Order {JSON.stringify(error)}</p>
      </UnPaddedPageContainer>
    )
  }

  if (loading || !order) {
    return <LoadingOrder />
  }

  const isReservedForInvestigationCheckbok = (
    <EditMode>
      <RXCell>
        <ListRowLabel>Reserved:</ListRowLabel>
        <ListRowValue>
          <CheckBox
            label="Yes"
            checked={isReservedForInvestigation}
            onChange={async e => {
              const { checked } = e.target
              setIsReservedForInvestigation(checked)
            }}
          />
        </ListRowValue>
      </RXCell>
    </EditMode>
  )

  const isReplacementCheckBox = (
    <EditMode>
      <RXCell>
        <ListRowLabel>Replacement:</ListRowLabel>
        <ListRowValue>
          <CheckBox
            label="Yes"
            disabled={order?.isReplacement}
            checked={isReplacement}
            onChange={async e => {
              const { checked } = e.target
              setIsReplacement(checked)
            }}
          />
        </ListRowValue>
      </RXCell>
    </EditMode>
  )

  if (!loading) {
    innerContents = (
      <>
        <Grid data-testid="order" columns={['flex', 'flex', 'flex']} gap="0.625rem">
          <Box data-test-column="patient">
            <FilledHeader>Patient</FilledHeader>
            {!!order?.patient && !!patientValues && (
              <PatientDetails
                condensedView={orderIsInPackingStatus}
                patient={order.patient}
                patientValues={patientValues}
                setPatientValues={setPatientValues}
              />
            )}
            {!!order?.guestPatient && !!guestPatientValues && (
              <GuestPatientDetails
                guestPatient={order.guestPatient as GuestPatient}
                guestPatientValues={guestPatientValues}
                setGuestPatientValues={setGuestPatientValues}
              />
            )}
          </Box>
          <Box data-test-column="payment">
            <FilledHeader>Payment and Shipping</FilledHeader>
            <PaymentAndShipping
              condensedView={orderIsInPackingStatus}
              disableOrder={disableOrder}
              order={order}
              paymentType={paymentType}
              setShippingAddressValues={setShippingAddressValues}
              setShippingMethod={setShippingMethod}
              setTrackingNumber={setTrackingNumber}
              shippingAddressValues={shippingAddressValues}
              shippingMethod={shippingMethod}
              trackingNumber={trackingNumber}
            />
          </Box>
          {inModal && (
            <Box data-test-column="references">
              <>
                <FilledHeader>References</FilledHeader>
                <RXCell data-test-row="notes">
                  <ListRowLabel>Customer notes:</ListRowLabel>
                  <ListRowValue>{apiRequest?.notes || 'None provided'}</ListRowValue>
                </RXCell>
              </>
            </Box>
          )}
          <Box key={trackingNumber} data-test-column="additional" data-test-viewmode={OrderViewMode[mode]}>
            {mode === OrderViewMode.Fulfillment && order.status === OrderStatus.Packing && (
              <>
                <FilledHeader>Tasks</FilledHeader>
                <PackingTasks key={order?.location?.legacyId} order={order} trackingNumber={trackingNumber} />
              </>
            )}

            {mode === OrderViewMode.Fulfillment && order.status === OrderStatus.Complete && (
              <>
                <FilledHeader>Event log</FilledHeader>
                <EventLog logs={logs} createdAt={order.createdAt} />
              </>
            )}

            {mode === OrderViewMode.Fulfillment &&
              [OrderStatus.PV1, OrderStatus.Fill, OrderStatus.PV2].includes(order.status) && (
                <>
                  <FilledHeader>References</FilledHeader>
                  {isReplacementCheckBox}
                  {isReservedForInvestigationCheckbok}
                </>
              )}

            {mode === OrderViewMode.Fulfillment && order.status === OrderStatus.Adjudication && (
              <EditMode>
                <FilledHeader>References</FilledHeader>
                {isReservedForInvestigationCheckbok}
              </EditMode>
            )}

            {mode === OrderViewMode.Pharmacy && (
              <>
                <FilledHeader>References</FilledHeader>
                <References order={order} />
                {isReplacementCheckBox}
                {order.status !== OrderStatus.Cancelled && isReservedForInvestigationCheckbok}
              </>
            )}
          </Box>
        </Grid>

        {order.rxFillRequests.length > 0 && (
          <FillList order={order} inModal={inModal} onClickOrderEntry={onClickOrderEntry} />
        )}

        {order.otcProducts.length > 0 && (
          <>
            <SubTitle>OTC</SubTitle>
            {mode === OrderViewMode.Fulfillment && order.status === OrderStatus.Packing ? (
              <OTCPackingItemList order={order} />
            ) : (
              <DataTable
                data={order.otcProducts}
                primaryKey="_id"
                columns={[
                  {
                    property: 'sku',
                    header: <>SKU</>,
                    render: ({ _id, barcode, sku }) =>
                      editMode && otcSkuValues ? (
                        <CompoundTextInput
                          value={otcSkuValues[_id] ?? ''}
                          onChange={e =>
                            setOtcSkuValues({
                              ...otcSkuValues,
                              // Intentionally default empty strings to null
                              [_id]: e.target.value || null,
                            })
                          }
                        />
                      ) : (
                        <>
                          {sku ?? <>&mdash;</>} {barcode && <> ({barcode})</>}
                        </>
                      ),
                  },
                  { property: 'name', header: <>Item</> },
                  { property: 'quantity', header: <>Qty</> },
                  {
                    property: 'pricing.cost',
                    header: <>Unit price</>,
                    render: ({ pricing }) => usdFormatter.format(pricing?.cost ?? 0),
                  },
                  // {
                  //   property: 'pricing.extended',
                  //   header: <>Extended price</>,
                  //   render: ({ pricing, quantity }) => usdFormatter.format((pricing?.cost ?? 0) * quantity),
                  // },
                  {
                    property: 'pricing.discount',
                    header: <>Discount</>,
                    render: ({ pricing }) =>
                      // usdFormatter.format((pricing?.total ?? 0) - (pricing?.cost ?? 0) - (pricing?.tax ?? 0)),
                      usdFormatter.format(pricing?.discount ?? 0),
                  },
                  {
                    property: 'pricing.tax',
                    header: <>Tax</>,
                    render: ({ pricing }) => usdFormatter.format(pricing?.tax ?? 0),
                  },
                  {
                    property: 'pricing.total',
                    header: <>Total</>,
                    render: ({ pricing }) => usdFormatter.format(pricing?.total ?? 0),
                  },
                  {
                    property: 'otcStatus',
                    header: <>Status</>,
                    render: ({ otcStatus }) => (
                      <Lozenge
                        backgroundColor={
                          otcStatus === OtcProductStatus.Packing ||
                          otcStatus === OtcProductStatus.PartialShipped ||
                          otcStatus === OtcProductStatus.Shipped
                            ? primaryColorDark
                            : primaryColor
                        }
                      >
                        {otcStatus}
                      </Lozenge>
                    ),
                  },
                ]}
              />
            )}
          </>
        )}

        {!!order.packingMaterials?.length && (
          <>
            <SubTitle>Packing List</SubTitle>
            <PackingMaterialsList order={order} />
          </>
        )}

        {!orderIsInPackingStatus && <ApiRequestDetails apiRequest={apiRequest} />}
      </>
    )
  }

  if (inModal) {
    return (
      <Box id="OrderView" direction="column" margin="0" width="80rem">
        {innerContents}
      </Box>
    )
  }

  const userEvent =
    mode === OrderViewMode.Fulfillment && order.inTriage
      ? 'triage'
      : mode === OrderViewMode.Fulfillment && order.status === OrderStatus.Packing
      ? 'packing'
      : 'view'

  const parentNotes =
    logs?.filter(note => note.event === 'note' && !note.isArchived && (!!note.prescriptionId || !!note.patientId))
      .length > 0
  const orderLevelNotes =
    logs?.filter(note => !!note.orderId && !note.fillId && note.event === 'note' && !note.isArchived) || []

  return (
    <>
      <UserEventTimer event={userEvent} orderId={order._id} />
      {order.inTriage && <TopBanner message="Triage" />}
      <PageContainer>
        <StickyPageHeadingContainer>
          <OrderTopBanner orderId={order._id} disableOrder={disableOrder} orderLockedBy={orderLockedBy} />
          <BreadCrumb />
          <PageHeading>
            <OrderHeading order={order} />
            {order && (
              <ActionButtons
                disableOrder={disableOrder}
                mode={mode}
                onCancel={onCancel}
                order={order}
                patientValues={patientValues}
                shippingAddressValues={shippingAddressValues}
                shippingMethod={shippingMethod}
                trackingNumber={trackingNumber}
                setTrackingNumber={setTrackingNumber}
                otcSkuValues={otcSkuValues}
                isReplacement={isReplacement}
                isReservedForInvestigation={isReservedForInvestigation}
                paymentType={paymentType}
              />
            )}
          </PageHeading>
        </StickyPageHeadingContainer>
        <PageContent>
          <Box direction="column" margin={{ left: '1.875rem' }} width="100rem">
            {order.inTriage && <TriageIssues item={order} />}
            {innerContents}
          </Box>
          <ToolBar>
            {!editValue && <EditButton orderId={orderId} />}
            <LogButton logs={logs} orderId={orderId} />
            <NotesButton notesCount={orderLevelNotes.length} showBadge={parentNotes || orderLevelNotes.length > 0} />
            {mode === OrderViewMode.Fulfillment && order?.patient && (
              <MedicalHistoryButton patientId={order.patient._id} />
            )}
            {mode === OrderViewMode.Pharmacy && <ReturnItemListButton order={order} />}
          </ToolBar>
        </PageContent>
      </PageContainer>
      {order && <MessagesPane logs={logs} />}
      {order && (
        <NotesPane
          isLoadingLogs={isLoadingLogs}
          filterOutEncounterRecordsFromOtherThanAllTabs
          logs={logs}
          item={order}
          patient={order?.patient}
          tabs={['Order', 'Patient']}
          defaultTab="Order"
          tabsIncludedInAllTab={shouldIncludePatientRelatedLogs ? ['Patient', 'Fill', 'Copay', 'Order', 'Rx'] : []}
        />
      )}
      {order && <AttachmentsPane orderId={order._id} patientId={patientValues?._id} />}
    </>
  )
}

const LoadingOrder = () => (
  <UnPaddedPageContainer>
    <LoadingSpinnerContainer>
      <LoadingSpinner />
    </LoadingSpinnerContainer>
  </UnPaddedPageContainer>
)

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 15rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`

export default OrderView
