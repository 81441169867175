import React, { useMemo, useState } from 'react'
import { useMutation } from '@truepill/tpos-react-router'
import { UserRoles } from '@truepill/tpos-types'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as ArchiveIcon } from 'assets/icons/inventory.svg'
import { ReactComponent as UnarchiveIcon } from 'assets/icons/reverse.svg'
import AuthLimited from 'components/AuthLimited'
import EditNoteModal from 'components/EditNoteModal'
import type { ToolTipFacing } from 'components/HotKeyToolTip'
import HotKeyToolTip from 'components/HotKeyToolTip'
import Select from 'components/Select'
import { ToolButton } from 'components/SideToolBar'
import Lozenge from 'components/Tiles/Lozenge'
import UserInitialsBadge from 'components/UserInitialsBadge'
import { UPDATE_NOTE } from 'gql'
import { Tab, Tabs } from 'grommet'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import moment from 'moment'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import styled from 'styled-components'
import {
  alertRed,
  bodyPrimaryColor,
  bodySecondaryColor,
  capsuleLightBlueColor,
  capsulePrimaryColor,
  contrastBackgroundColor,
  primaryBackgroundColor,
  primaryColor,
  subduedColor,
} from 'styles/styleVariables'
import type { Log } from 'types'
import { NoteSpecialTags } from 'types'
import type { NoteListProps, NoteTab } from './types'

const populators: Record<NoteTab, (allNotes: Log[]) => Log[]> = {
  All: (allNotes: Log[]) => allNotes,
  Fill: (allNotes: Log[]) => allNotes.filter(note => !!note.fillId),
  Order: (allNotes: Log[]) => allNotes.filter(note => !!note.orderId && !note.fillId),
  Copay: (allNotes: Log[]) => allNotes.filter(note => !!note.copayRequestId && !note.fillId),
  Rx: (allNotes: Log[]) => allNotes.filter(note => !!note.prescriptionId),
  Patient: (allNotes: Log[]) => allNotes.filter(note => !!note.patientId),
  Critical: (allNotes: Log[]) => allNotes.filter(note => note.tags.includes(NoteSpecialTags.CRITICAL)),
  External: (allNotes: Log[]) => allNotes.filter(note => note.tags.includes(NoteSpecialTags.EXTERNAL)),
}

const adaptLogs = (
  notes: Log[],
  tabs: NoteTab[],
  tabsOrder: NoteTab[],
  tabsIncludedInAllTab: NoteTab[],
  filterOutEncounterRecordsFromOtherThanAllTabs: boolean,
) => {
  const tabsToDisplay = tabsOrder.filter((key: NoteTab) => tabs.includes(key))
  const filteredNotes = filterOutEncounterRecordsFromOtherThanAllTabs
    ? notes.filter(note => !note.tags.includes(NoteSpecialTags.ENCOUNTER))
    : notes
  const tabsContents: Partial<Record<NoteTab, Log[]>> = {}

  // populate tab contents
  tabsToDisplay.forEach(tab => {
    const isAllTab = tab === 'All'
    if (isAllTab && tabsIncludedInAllTab.length) return
    tabsContents[tab] = populators[tab](isAllTab ? notes : filteredNotes)
  })

  // populate the 'all' tab with the content from the desired sections/tabs
  if (tabsIncludedInAllTab.length) {
    tabsContents.All = tabsIncludedInAllTab.reduce((accumulator, currVal) => {
      const contents = populators[currVal](filteredNotes)

      if (contents) {
        accumulator = [...accumulator, ...contents]
      }
      return accumulator
    }, [] as Log[])

    // also add encounter notes to the 'all' tab
    tabsContents.All = tabsContents.All?.concat(notes.filter(note => note.tags.includes(NoteSpecialTags.ENCOUNTER)))
  }

  // finally sort the 'all' tab
  tabsContents.All?.sort((logA: Log, logB: Log) => {
    const aCreated = logA.createdAt
    const bCreated = logB.createdAt
    if (aCreated > bCreated) {
      return -1
    } else if (aCreated < bCreated) {
      return 1
    } else {
      return 0
    }
  })
  return { tabsContents, tabsToDisplay }
}

//This is to properly infer the string type
function getTabTitle(tab: NoteTab, overrideTabNames: NoteListProps['overrideTabNames']): string {
  const title = overrideTabNames?.[tab]
  return title ? title : tab
}

const NotesListAlt = (props: NoteListProps): JSX.Element => {
  const {
    notes = [],
    tabs = [],
    defaultTab,
    excludeAllTab = false,
    excludeCriticalTab = false,
    excludeExternalTab = false,
    filterOutEncounterRecordsFromOtherThanAllTabs = false,
    // filter which sections to include in the all section
    tabsIncludedInAllTab = [],
    overrideTabNames,
    tabsOrder = ['All', 'Fill', 'Order', 'Copay', 'Rx', 'Patient', 'Critical', 'External'],
  } = props

  // All, Critical and External are default
  if (!excludeCriticalTab && !tabs.includes(NoteSpecialTags.CRITICAL)) tabs.push(NoteSpecialTags.CRITICAL)
  if (!excludeExternalTab && !tabs.includes(NoteSpecialTags.EXTERNAL)) tabs.push(NoteSpecialTags.EXTERNAL)
  if (!excludeAllTab && !tabs.includes('All')) tabs.push('All')

  const { tabsContents, tabsToDisplay } = useMemo(
    () => adaptLogs(notes, tabs, tabsOrder, tabsIncludedInAllTab, filterOutEncounterRecordsFromOtherThanAllTabs),
    [filterOutEncounterRecordsFromOtherThanAllTabs, tabsIncludedInAllTab, notes, tabs, tabsOrder],
  )

  const hasValues = Object.values(tabsContents).some(arr => arr.length > 0)

  const [activeTab, setActiveTab] = useState(defaultTab ? tabsToDisplay.indexOf(defaultTab) : 0)

  return (
    <React.Fragment>
      {hasValues ? (
        <StyledTabs justify="start" activeIndex={activeTab} onActive={index => setActiveTab(index)}>
          {tabsToDisplay.map((key, i) => {
            const title = getTabTitle(key, overrideTabNames)

            return (tabsContents[key as keyof typeof tabsContents]?.length ?? 0) > 0 ? (
              <StyledTabDark title={title} key={i}>
                <StyledList>
                  <NoteEntryContainer>
                    {tabsContents[key as keyof typeof tabsContents]?.map((note: Log) => (
                      <NoteEntry
                        key={note._id}
                        note={note}
                        tab={title}
                        excludeCritical={excludeCriticalTab}
                        excludeExternal={excludeExternalTab}
                      />
                    ))}
                  </NoteEntryContainer>
                </StyledList>
              </StyledTabDark>
            ) : (
              <EmptyNotes key={key} title={key}></EmptyNotes>
            )
          })}
        </StyledTabs>
      ) : (
        <StyledCustomTabs onActive={index => setActiveTab(index)} activeIndex={activeTab}>
          {tabsToDisplay.map((tabName, idx) => {
            const title = getTabTitle(tabName, overrideTabNames)

            return (tabsContents[tabName as keyof typeof tabsContents]?.length ?? 0) > 0 ? (
              <StyledSelectedTab title={title} key={idx}>
                <StyledList>
                  <NoteEntryContainer>
                    {tabsContents[tabName as keyof typeof tabsContents]?.map((note: Log, i: number) => (
                      <NoteEntry
                        key={i}
                        note={note}
                        tab={title}
                        excludeCritical={excludeCriticalTab}
                        excludeExternal={excludeExternalTab}
                      />
                    ))}
                  </NoteEntryContainer>
                </StyledList>
              </StyledSelectedTab>
            ) : (
              <EmptyNotes key={idx} title={title}></EmptyNotes>
            )
          })}
        </StyledCustomTabs>
      )}
    </React.Fragment>
  )
}

type EmptyNotesProps = { title: string }
export const EmptyNotes = (props: EmptyNotesProps): JSX.Element => {
  const { title = '' } = props

  const [showToolTip, setShowToolTip] = useState(false)

  const tooltipPositioning = {
    position: 'top' as ToolTipFacing,
    offsetTop: -2.0,
    offsetLeft: 2.0,
  }
  return (
    <StyledNoNotes
      onMouseEnter={() => {
        setShowToolTip(true)
      }}
      onMouseLeave={() => {
        setShowToolTip(false)
      }}
    >
      <HotKeyToolTip
        forceShow={showToolTip}
        onMouseEnter={() => {
          setShowToolTip(false)
        }}
        label={'No notes available'}
        forceHide={false}
        {...tooltipPositioning}
      />
      <StyledDivNoNotes>
        <StyledSpanNoNotes>{title}</StyledSpanNoNotes>
      </StyledDivNoNotes>
    </StyledNoNotes>
  )
}

const getLozengeText = (log: Partial<Log>) =>
  (log.fillId && log.orderId && 'Order-Fill') ||
  (log.fillId && log.copayRequestId && 'Copay-Fill') ||
  (log.fillId && 'Fill') ||
  (log.orderId && 'Order') ||
  (log.copayRequestId && 'Copay') ||
  (log.prescriptionId && 'Prescription') ||
  (log.patientId && 'Patient') ||
  (log.prescriberId && 'Prescriber') ||
  ''

interface NoteEntryProps {
  note: Partial<Log>
  tab?: string
  excludeCritical?: boolean
  excludeExternal?: boolean
}

const tokenText = {
  Fill: (note: Partial<Log>) => note.copayRequest?.coreCopayToken ?? note.order?.coreOrderToken,
  Order: (note: Partial<Log>) => note.order?.coreOrderToken,
  Copay: (note: Partial<Log>) => note.copayRequest?.coreCopayToken,
  Prescription: (note: Partial<Log>) => note.prescription?.rxNumber,
}

const getTokenText = (lozengeText: string, note: Partial<Log>) => {
  if (lozengeText) {
    return lozengeText in tokenText ? tokenText[lozengeText as keyof typeof tokenText](note) : ''
  }
  return ''
}

export const NoteEntry = (props: NoteEntryProps): JSX.Element => {
  const { note, tab, excludeCritical = false, excludeExternal = false } = props
  const { _id: noteId, user, message, updatedAt, tags = [], isArchived = false } = note
  const { showModal } = useModalContext()
  const showSuccessToast = useSuccessToast()
  const showErrorToast = useErrorToast()

  const [updateNote] = useMutation(UPDATE_NOTE, {
    onCompleted({ updateNote }) {
      showSuccessToast(`Note ${updateNote.isArchived ? '' : 'un'}archived`)
    },
    onError(err) {
      showErrorToast(`Failed to archive note! ${err.message.replace('GraphQL error:', '')}`)
    },
  })

  if (!user) {
    return <></>
  }

  const lozengeText = getLozengeText(note)
  const showLozengeText = tab === 'Rx' ? lozengeText !== 'Prescription' : tab !== lozengeText

  const tooltipPositioning = {
    position: (isArchived ? 'left' : 'top') as ToolTipFacing,
    offsetTop: isArchived ? 0.5 : -2,
    offsetLeft: 0.85,
  }

  return (
    <StyledNote data-testid={`Note-${noteId}`}>
      <Row>
        <BadgeContainer data-testid="badge">
          {user.firstName && user.lastName && <UserInitialsBadge user={user} />}
        </BadgeContainer>
        <UserName data-testid="user">
          {user.firstName} {user.lastName}
        </UserName>
        <Date data-testid="date">{moment(updatedAt).format('MM/DD/YYYY, h:mmA')}</Date>
        <span>{getTokenText(lozengeText, note)}</span>
        <AuthLimited roles={[UserRoles.Pharmacist, UserRoles.LeadPharmacist, UserRoles.LeadCustomerSupport]}>
          <ButtonsContainer id="NoteButtonsContainer">
            <ArchiveButton
              label={isArchived ? 'Unarchive' : 'Archive'}
              tooltipText={isArchived ? 'Unarchive' : 'Archive'}
              icon={isArchived ? UnarchiveIcon : ArchiveIcon}
              tooltipPositioning={tooltipPositioning}
              clickCallback={() =>
                updateNote({
                  variables: {
                    noteId,
                    isArchived: !isArchived,
                  },
                })
              }
            />
            {!isArchived && (
              <EditButton
                label="Edit"
                tooltipText="Edit"
                icon={EditIcon}
                tooltipPositioning={tooltipPositioning}
                clickCallback={() =>
                  showModal(() => (
                    <EditNoteModal note={note} excludeExternal={excludeExternal} excludeCritical={excludeCritical} />
                  ))
                }
              />
            )}
          </ButtonsContainer>
        </AuthLimited>
      </Row>
      <Row withPadding>
        {showLozengeText && (
          <StyledLozenge data-testid="type" backgroundColor={primaryColor}>
            {lozengeText}
          </StyledLozenge>
        )}
        {tags.map((tag, index) => {
          return <TagLozenge tag={tag} key={index} tab={tab} />
        })}
      </Row>
      <MessageRow data-testid="message" isArchived={isArchived} withPadding>
        {message}
      </MessageRow>
    </StyledNote>
  )
}

const TagLozenge = (props: { tag: string; tab?: string }) => {
  const { tag, tab } = props

  if (!tag || tag === tab) {
    return null
  }

  if (tag === NoteSpecialTags.ENCOUNTER) {
    return (
      <StyledLozenge data-testid="tag" backgroundColor={capsuleLightBlueColor} color={capsulePrimaryColor}>
        {tag}
      </StyledLozenge>
    )
  }

  if (tag === NoteSpecialTags.CRITICAL) {
    return (
      <StyledLozenge data-testid="tag" backgroundColor={alertRed}>
        {tag}
      </StyledLozenge>
    )
  }

  return (
    <StyledLozenge data-testid="tag" backgroundColor={subduedColor}>
      {tag}
    </StyledLozenge>
  )
}

const StyledNoNotes = styled.div`
  background-color: transparent;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  font: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin: 0;
  background: transparent;
  overflow: visible;
  text-transform: none;
  color: inherit;
  outline: none;
  border: none;
  padding: 0;
  text-align: inherit;
  font-weight: 500;
`

const StyledDivNoNotes = styled.div`
  margin-left: 9px;
  margin-right: 9px;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: transparent;
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  flex-direction: column;
  padding-bottom: 6px;
`

const StyledSpanNoNotes = styled.span`
  color: ${subduedColor};
  font-size: 1rem;
  line-height: 24px;
`

const StyledLozenge = styled(Lozenge)`
  &:first-of-type {
    margin-left: 0;
  }
`

const BadgeContainer = styled.span`
  font-size: 1.25rem;
`

const UserName = styled.p`
  font-weight: 500;
`

const NoteEntryContainer = styled.div`
  width: 100%;
  max-height: 75vh;
  overflow-y: auto;
`

const Date = styled.p`
  color: ${bodySecondaryColor};
`

const StyledList = styled.ul`
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  flex-grow: 1;
  overflow-y: auto;
`

const Row = styled.span<{ withPadding?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${({ withPadding }) => withPadding && 'padding: 0.25rem 0.5rem 0;'}
  > :not(:last-child) {
    margin-right: 0.625rem;
  }
`
const StyledCustomTabs = styled(Tabs)`
  margin-top: 1.5rem;
  border-bottom: 0.1px solid #cccccc;
  align-items: flex-start;
  justify-content: flex-start;
`

const StyledSelectedTab = styled(Tab)<{ active?: boolean }>`
  background-color: transparent;
  justify-content: center;
  > div {
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: transparent;
    align-items: center;
    > span {
      color: ${({ active }) => (active ? primaryColor : bodyPrimaryColor)};
      ${({ active }) => active && `border-bottom: 4px solid ${primaryColor};`}
    }
  }
`

const StyledNote = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 1.25rem;
  padding: 0.5rem;
  > :not(:first-child) {
    margin-top: 0.3125rem;
  }

  :hover {
    background-color: ${contrastBackgroundColor};
  }

  :hover #NoteButtonsContainer {
    display: flex;
  }
`

export const StyledSelect = styled(Select)`
  width: 100%;
  flex: 1;
  align-self: center;
  background-color: ${primaryBackgroundColor};
  overflow: visible;
  min-height: 2rem;
  margin: 0.125rem;
`

const StyledTabs = styled(Tabs)`
  background-color: transparent;
  margin-top: 1.5rem;
`

const StyledTabDark = styled(Tab)<{ active?: boolean }>`
  background-color: transparent;
  > div {
    margin-left: 9px;
    margin-right: 9px;
    background-color: transparent;
    > span {
      color: ${({ active }) => (active ? primaryColor : bodyPrimaryColor)};
      ${({ active }) => active && `border-bottom: 4px solid ${primaryColor};`}
    }
  }
`

const ButtonsContainer = styled.div`
  display: none;
  flex-grow: 1;
  justify-content: flex-end;
  height: 1.5rem;
`

const EditButton = styled(ToolButton)`
  background-color: transparent;
  border: none;
  max-height: 1.5rem;
  :hover,
  :focus {
    box-shadow: none !important;
    cursor: pointer;
  }
`

const ArchiveButton = styled(ToolButton)`
  background-color: transparent;
  border: none;
  margin-right: 0;
  max-height: 1.5rem;
  :hover,
  :focus {
    box-shadow: none !important;
    cursor: pointer;
  }
`

const MessageRow = styled(Row)<{ isArchived: boolean }>`
  ${({ isArchived }) => isArchived && 'text-decoration: line-through'};
`

export default NotesListAlt
