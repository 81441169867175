import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useQuery } from '@truepill/tpos-react-router'
import { licenseRequiredStates, PaymentType, RxFillRequestStatus, SystemTriageReasons } from '@truepill/tpos-types'
import AttachmentsPane from 'components/AttachmentsPane'
import LoadingSpinner from 'components/Loading'
import MessagesPane from 'components/MessagesPane'
import NotesPane from 'components/NotesPane'
import type { NoteTab } from 'components/NotesPane/NotesList/types'
import { UnPaddedPageContainer } from 'components/PageStructure'
import UserEventTimer from 'components/UserEventTimer'
import { GET_DOCUMENTS } from 'gql'
import { useOrderLock, useSetPageTitle, useUnlockOrder, useUserInfo } from 'hooks'
import useFill from 'hooks/navigation/useFill'
import useLogs from 'hooks/navigation/useLogs'
import useErrorToast from 'hooks/toast/useErrorToast'
import useShouldIncludePatientRelatedLogs from 'hooks/useShouldIncludePatientRelatedLogs'
import ExcessiveCsRejectionsModal from 'modals/ExcessiveCsRejectionsModal'
import MissingLicenseModal from 'modals/MissingLicenseModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import type { Fill, Log, Order, Patient, Prescription, PriorAuthorization, RXFillRequest, TPOSDocument } from 'types'
import useLogPageView from '../../../../hooks/useLogPageView'
import ClaimsView from './screens/claims'
import CompleteView from './screens/complete'
import FillView from './screens/fill'
import ManualPriorAuthView from './screens/priorAuth/ManualPriorAuthView'
import PV1View from './screens/pv1'
import PV2View from './screens/pv2'

type RXPageProps = { orderId: string; fillId: string }
const notesPaneTabs: NoteTab[] = ['Fill', 'Order', 'Rx', 'Patient']
const overridenTabNames: Partial<Record<NoteTab, string>> = { Fill: 'Order-Fill' }

const RXPage = ({ orderId, fillId }: RXPageProps): JSX.Element => {
  const { order, prescription, fill, patient, rxFillRequest, loading, error } = useFill({ orderId, fillId })

  useLogPageView(
    {
      page: `rxFillRequest/${rxFillRequest?.status}${order?.inTriage ? '/Triage' : ''}`,
      orderId: orderId,
      fillId: fillId,
    },
    Boolean(!loading && rxFillRequest?.status && order),
  )

  const {
    tokenContext: { id: userId },
  } = usePlusClient()
  const { showModal } = useModalContext()
  //JR-11501 Prevent PV1 Order from switching to Fill/Automation page when getNextPV1 isn't fast enough
  const [forceLoadingSpinner, setForceLoadingSpinner] = useState(false)
  const { userInfo, loading: loadingInfo } = useUserInfo(userId)

  const currentDate = new Date()
  const stateLicenses =
    userInfo?.licenses?.filter(lic => new Date(lic.expirationDate) > currentDate).map(lic => lic.registeredState) || []
  const locationLiceses = userInfo?.location?.licensedStates ?? []
  const availableLiceses = [...stateLicenses, ...locationLiceses]

  const { setOrderLock, orderLockedBy } = useOrderLock(order?._id)
  const showErrorToast = useErrorToast()
  useUnlockOrder(order?._id)

  useEffect(() => {
    if (!orderLockedBy) {
      setOrderLock()
        .then(() => localStorage.setItem(`orderId`, order?._id))
        .catch(e => showErrorToast(`Failed to lock order ${(e as Record<string, string>).message}`))
    }
  }, [order?._id, orderLockedBy, setOrderLock, showErrorToast])

  const shouldIncludePatientRelatedLogs = useShouldIncludePatientRelatedLogs()
  const { logs, loading: isLoadingLogs } = useLogs(
    {
      orderId: orderId,
      fillId: fillId,
      patientId: patient?._id,
      prescriptionId: prescription?._id,
      prescriberId: prescription?.prescriberId,
    },
    { shouldIncludePatientRelatedLogs },
  )
  const { data: documents } = useQuery<{ getDocuments: TPOSDocument[] }>(GET_DOCUMENTS, {
    variables: {
      orderId,
      fillId,
      prescriptionId: prescription?._id,
      patientId: patient?._id,
    },
  })

  useSetPageTitle('Order Fill')

  const shouldShowExcessiveCsRejectionsModal: boolean =
    (!!logs?.filter(log => log?.message?.includes('Controlled substance fill rejection #10 from this prescriber'))
      ?.length ||
      !!logs?.filter(log => log?.message?.includes('Controlled substance fill rejection #50 from this customer'))
        ?.length) &&
    !logs?.filter(log => log?.message?.includes('Excessive CS rejections reported by'))?.length
  useEffect(() => {
    if (shouldShowExcessiveCsRejectionsModal) {
      showModal(() => <ExcessiveCsRejectionsModal orderId={order?._id} fillId={fill?._id} />)
    }
  }, [shouldShowExcessiveCsRejectionsModal])

  if (error) {
    return (
      <UnPaddedPageContainer>
        <p>Error loading Rx {JSON.stringify(error)}</p>
      </UnPaddedPageContainer>
    )
  }

  if (loading || !order || !prescription || loadingInfo || forceLoadingSpinner) {
    return <LoadingOrder />
  }

  return (
    <>
      <UnPaddedPageContainer>
        <RXView
          order={order}
          fill={fill}
          prescription={prescription}
          rxFillRequest={rxFillRequest}
          patient={patient}
          loading={loading}
          docs={documents?.getDocuments || []}
          orderLogs={logs || []}
          stateLicenses={availableLiceses}
          forceLoadingSpinner={setForceLoadingSpinner}
        />
      </UnPaddedPageContainer>
      <MessagesPane logs={logs} />
      <NotesPane
        filterOutEncounterRecordsFromOtherThanAllTabs
        isLoadingLogs={isLoadingLogs}
        tabsIncludedInAllTab={shouldIncludePatientRelatedLogs ? ['Patient', 'Fill', 'Copay', 'Order', 'Rx'] : []}
        overrideTabNames={overridenTabNames}
        logs={logs}
        item={order}
        fill={fill}
        filterOutByFillId={!shouldIncludePatientRelatedLogs}
        prescription={prescription}
        patient={patient}
        defaultTab="All"
        tabs={notesPaneTabs}
      />
      <AttachmentsPane orderId={orderId} fillId={fillId} prescriptionId={prescription._id} patientId={patient._id} />
    </>
  )
}
export default RXPage

type RXViewProps = {
  order: Order
  fill: Fill
  prescription: Prescription
  rxFillRequest: RXFillRequest
  patient: Patient
  docs: TPOSDocument[]
  orderLogs: Log[]
  loading: boolean
  stateLicenses: string[]
  forceLoadingSpinner?: Dispatch<SetStateAction<boolean>>
  priorAuthorization?: PriorAuthorization
}

const RXView = ({
  order,
  fill,
  prescription,
  rxFillRequest,
  patient,
  docs,
  orderLogs,
  loading,
  stateLicenses,
  forceLoadingSpinner,
  priorAuthorization,
}: RXViewProps) => {
  if (loading || !order || !rxFillRequest) {
    return <></>
  }
  let view
  const { status, adjudication, paymentType } = rxFillRequest
  const orderHasSystemTriage = order.triages
    .filter(triage => !triage.endDate)
    .find(triage => Object.keys(SystemTriageReasons).includes(triage.reason))
  // If adjudication is false or undefined, and request is of type insurance, that means we do not have a paid claim and we should show the Adjudication page
  const orderIsCompleteWithReversedClaim =
    status === RxFillRequestStatus.Complete && paymentType === PaymentType.Insurance && !adjudication?.runSuccessful

  const requiredStates: string[] = Object.values(licenseRequiredStates)
  const shippingState: string = order?.shippingAddress?.state

  const userRequiredLicenses: string[] = stateLicenses.filter(license => requiredStates.includes(license))

  const showMissingLicenseModal: boolean =
    requiredStates.includes(shippingState) && !userRequiredLicenses.includes(shippingState)

  if (orderIsCompleteWithReversedClaim || (!orderHasSystemTriage && status === RxFillRequestStatus.Adjudication)) {
    view = (
      <ClaimsView
        fill={fill}
        item={order}
        logs={orderLogs}
        patient={patient}
        prescription={prescription}
        rxFillRequest={rxFillRequest}
      />
    )
  } else if (!orderHasSystemTriage && status === RxFillRequestStatus.PriorAuthorization) {
    view = (
      <ManualPriorAuthView
        order={order}
        fill={fill}
        prescription={prescription}
        patient={patient}
        rxFillRequest={rxFillRequest}
        docs={docs}
        orderLogs={orderLogs}
      />
    )
  } else if (!order.inTriage && status === RxFillRequestStatus.PV2) {
    view = (
      <>
        <MissingLicenseModal shouldOpen={showMissingLicenseModal} />
        <PV2View
          order={order}
          fill={fill}
          prescription={prescription}
          patient={patient}
          rxFillRequest={rxFillRequest}
          docs={docs}
          orderLogs={orderLogs}
          disableRphButtons={false}
        />
      </>
    )
  } else if (!order.inTriage && (status === RxFillRequestStatus.Fill || status === RxFillRequestStatus.Automation)) {
    view = (
      <FillView
        order={order}
        fill={fill}
        prescription={prescription}
        patient={patient}
        rxFillRequest={rxFillRequest}
        docs={docs}
        orderLogs={orderLogs}
      />
    )
  } else if (status === RxFillRequestStatus.Complete) {
    view = (
      <CompleteView
        order={order}
        fill={fill}
        prescription={prescription}
        patient={patient}
        rxFillRequest={rxFillRequest}
        docs={docs}
        orderLogs={orderLogs}
      />
    )
  } else {
    view = (
      <>
        <MissingLicenseModal shouldOpen={showMissingLicenseModal} />
        <PV1View
          order={order}
          fill={fill}
          prescription={prescription}
          patient={patient}
          rxFillRequest={rxFillRequest}
          docs={docs}
          orderLogs={orderLogs}
          disableRphButtons={false}
          forceLoadingSpinner={forceLoadingSpinner}
        />
      </>
    )
  }

  let event = 'fulfillment:'

  if (order.inTriage) event += 'triage:'

  event += rxFillRequest.status

  return (
    <>
      <UserEventTimer orderId={order._id} rxFillRequestId={rxFillRequest._id} event={event} />
      {view}
    </>
  )
}

export const LoadingOrder = () => (
  <UnPaddedPageContainer>
    <LoadingSpinnerContainer>
      <LoadingSpinner />
    </LoadingSpinnerContainer>
  </UnPaddedPageContainer>
)

const LoadingSpinnerContainer = styled.div`
  display: flex;
  padding-top: 15rem;
  justify-content: center;
  svg {
    height: 126px;
  }
`
